export const filterOptionsTypes = {
  numbers: [
    "first_deposit",
    "deposits_sum",
    "deposits_count",
    "net_gaming",
    "bonuses",
    "balance",
    "hits",
    "hosts",
    "regs",
    "rounds",
    "qualified_players",
    "first_deposits",
    "deposits",
    "withdraws",
    "ng",
    "comppoints",
    "casino_profit",
    "partner_income",
    "payouts",
  ],
  strings: [
    "gamer_id",
    "partner_id",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "duplicates",
  ],
};
