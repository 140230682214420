import React from "react";
import { isCurrencyField } from "../../../../../modules/Dashboard/constants";
import { CurrencyLabel } from "../../../../../modules/Common/components";
import { getCurrencySymbol } from "../../../../../common/helpers";

const RenderSubtotalsCell = ({ column, value, date }) => {
  if (isCurrencyField(column))
    return <CurrencyLabel value={value} symbol={getCurrencySymbol(date)} />;
  return <>{value}</>;
};
export default RenderSubtotalsCell;
