import Banner from "../../images/icons/promo/banner.svg";
import Branding from "../../images/icons/promo/branding.svg";
import DefaultImg from "../../images/icons/promo/default.svg";
import DirectLink from "../../images/icons/promo/direct_link.svg";
import Video from "../../images/icons/promo/video.svg";

interface PromoProjectData {
  id: number;
  is_recommend: number;
  tournament: number;
  domain: string;
  url: string;
  name: string;
  image: string;
}

interface PromoOptionsData {
  allowed_countries: string;
  revshare_reward: number;
  transaction_commission: string;
  royalty: string;
}

export interface PromoProgramsData {
  id: string;
  name: string;
  enabled: boolean;
  amount: string;
  amountFor: string;
  formula: string;
  icon: string;
  type: string;
  project: PromoProjectData;
  options: PromoOptionsData;
}

export interface PromoProgramsResponce {
  data: PromoProgramsData[];
}

export interface PromoTypesData {
  [key: string]: string;
}

export interface PromoTypesResponce {
  data: PromoTypesData[];
}

export interface PromoProjectTypesData {
  id: string;
  name: string;
  is_new: boolean;
}

export interface PromoProjectTypesResponce {
  data: {
    promo_types: PromoProjectTypesData;
  };
}

export interface PromoTrackersData {
  [key: string]: string;
}

export interface PromoTrackersResponce {
  data: PromoTrackersData[];
}

export interface PromoSourceOptionsData {
  type_id: number;
  label: string;
}

export interface PromoSourceOptionsResponce {
  data: {
    types: PromoSourceOptionsData[];
  };
}

export interface PromoProjectsData {
  [key: string]: string;
}

export interface PromoProjectsResponce {
  data: PromoProjectsData[];
}

export interface promoMaterialsRequest {
  project_id: number | string;
  promo_id: number | string;
  type: number | string;
  page: number | string;
}

export interface ArchivePromoItemTypes {
  code: string;
  created_at: string;
  geo_target: string;
  id: number;
  landing_page_id: number;
  landing_type: number;
  name: string;
  postback_deposit_url: string;
  postback_first_deposit_url: string;
  postback_install_url: string;
  postback_qualification_url: string;
  postback_register_url: string;
  program_full_name: string;
  program_id: number;
  program_name: string;
  project_id: number;
  project_name: string;
  source_raw: string;
  source_type: number;
  sub_id: string;
  sub_id2: string | null;
  sub_id_param: string;
  template_extension: string;
  template_id: number;
  template_name: string;
  tracker_id: string | null;
  type: number;
  type_label: string;
  use_redirector: number;
}

export interface ArchivePromoFilter {
  project_id: number | null;
  promo_id: number | null;
  type: string;
}

export interface ArchivePromoPagination {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface ArchivePromoData {
  data: {
    promos: {
      [key: number]: ArchivePromoItemTypes;
    };
    totals: ArchivePromoPagination;
    filters: ArchivePromoFilter;
  };
}

export interface OrderRequestData {
  details: string;
  contacts: string;
}

export interface SendOrderResponce {
  data: {
    message: string;
    status: string;
  };
}

export interface RawCodeRequestData {
  id: number | string;
  extra_parameters?: string;
}

export interface RawCodeResponce {
  data: string;
}
export interface PromoTemplatesFiltersItem {
  id: string | number;
  count: number;
  name: string;
  active: number;
}

export interface PromoTemplatesFilters {
  id: number;
  items: PromoTemplatesFiltersItem[];
}

export interface PromoTemplatesItems {
  code: string;
  id: string;
  isMobile: boolean;
  options: {
    height: number;
    width: number;
    ext: string;
    src: string;
  };
}

export interface PromoTemplatesLanguage {
  count: number;
  key: string;
  name: string;
}
export interface PromoTemplatesFiltersObject {
  language: PromoTemplatesLanguage[];
}

export interface PromoTemplatesResponce {
  data: {
    items: PromoTemplatesItems[];
    filters: PromoTemplatesFilters[] | PromoTemplatesFiltersObject;
  };
}

export interface PromoLandingsItems {
  id: string;
  name: string;
  is_promo: number;
  language: string[];
  url: string;
  image: string;
}

export interface PromoLandingsResponce {
  data: {
    items: PromoLandingsItems[];
    filters: PromoTemplatesFiltersObject;
    status?: string;
    messages?: string;
  };
}

export interface PromoTemplatesRequestData {
  project_id: number | string;
  type: number | string;
  tags?: string;
}

export interface PromoLandingsRequestData {
  project_id: number | string;
  type: number | string;
  template_id: number | string;
}

export const TemplatesFiltersTitle = {
  2: "language",
  36: "square",
  1: "tag",
  64: "format",
  20: "vertical",
  12: "horizontal",
};

export const PromoTypes = {
  1: { id: "1", name: "Direct Link", img: DirectLink },
  2: { id: "2", name: "Banner", img: Banner },
  7: { id: "7", name: "Branding", img: Branding },
  6: { id: "6", name: "Clickunder", img: DefaultImg },
  9: { id: "9", name: "Video", img: Video },
};

export const simpleItems = ["gif", "jpeg", "png", "jpg"];
