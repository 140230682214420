import axiosClient from "../../system/helpers/axiosConfig";
import {
  PromoProgramsResponce,
  PromoTypesResponce,
  promoMaterialsRequest,
  PromoProjectsResponce,
  PromoTrackersResponce,
  ArchivePromoData,
  OrderRequestData,
  SendOrderResponce,
  PromoProjectTypesResponce,
  RawCodeRequestData,
  RawCodeResponce,
  PromoSourceOptionsResponce,
  PromoTemplatesResponce,
  PromoTemplatesRequestData,
  PromoLandingsRequestData,
  PromoLandingsResponce,
} from "./constants";
import { notify } from "../../common/helpers";

export const getPromoPrograms = () => {
  return axiosClient
    .get<PromoProgramsResponce>("/api/promo/programs")
    .then((r) => r.data);
};

export const getPromoMaterials = (params: promoMaterialsRequest) => {
  return axiosClient
    .get<ArchivePromoData>(`/api/promo/materials`, {
      params,
    })
    .then((r) => r.data);
};

export const getPromoTypes = () => {
  return axiosClient
    .get<PromoTypesResponce>("/api/promo/types")
    .then((r) => r.data);
};

export const getPromoProjectTypes = (projectId: string) => {
  return axiosClient
    .get<PromoProjectTypesResponce>(`/api/promo/promo-types/${projectId}`)
    .then((r) => r.data);
};

export const getPromoProjects = () => {
  return axiosClient
    .get<PromoProjectsResponce>("/api/promo/projects")
    .then((r) => r.data);
};

export const getPromoTrackers = () => {
  return axiosClient
    .get<PromoTrackersResponce>("/api/promo/trackers")
    .then((r) => r.data);
};

export const getPromoSourceOptions = (params: string | number) => {
  return axiosClient
    .get<PromoSourceOptionsResponce>("/api/promo/source-options", {
      params,
    })
    .then((r) => r.data);
};

export const getPromoTemplates = (params: PromoTemplatesRequestData) => {
  return axiosClient
    .get<PromoTemplatesResponce>("/api/promo/promo-templates", {
      params,
    })
    .then((r) => r.data);
};

export const getPromoLandings = (params: PromoLandingsRequestData) => {
  return axiosClient
    .get<PromoLandingsResponce>("/api/promo/landings", {
      params,
    })
    .then((r) => {
      if (r.data.data.status === "error") {
        notify("error", r.data?.data?.messages || "error");
      }

      return r.data;
    });
};

export const sendOrder = (data: OrderRequestData) => {
  return axiosClient
    .post<SendOrderResponce>(`/api/promo/order`, data)
    .then((r) => r.data.data);
};

export const getRawCode = (data: RawCodeRequestData) => {
  return axiosClient
    .post<RawCodeResponce>(`/api/promo/source`, data)
    .then((r) => r.data.data);
};

export const updatePromoMaterial = (data) => {
  return axiosClient
    .post(`/api/promo/update/${data.id}`, data)
    .then((r) => r.data.data);
};

export const createPromoMaterial = (data) => {
  return axiosClient
    .post("/api/promo/materials", data)
    .then((r) => r.data.data);
};

export const getPromoArchive = () => {
  return axiosClient.get("/api/promo/promo-archive").then((r) => r.data);
};
