import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";
import icon from "../../../../assets/images/outer/commission_1.svg";

const Revshare = () => {
  const points = (_t: any) => [
    _t("revshare_1"),
    _t("revshare_2"),
    _t("revshare_3"),
    _t("revshare_4"),
  ];
  const title = _t("revshare_title");
  return <CommissionPage title={title} icon={icon} items={points} />;
};

export default Revshare;
