import React, { useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import axiosClient from "../../../../../../../system/helpers/axiosConfig";
import { ExcelButton } from "../../../../../../../modules/Dashboard/components/Filters/ExcelButton";
import { useGetReportFile } from "../../../../hooks/api";
import { Spin, Table } from "antd";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import { config } from "../../config";
import { createColumns } from "../../../../utils";
import { RenderSubtotals } from "../../../../components";
import { useSubtotals } from "../../../../hooks/table";
import { useGetGeoDetails } from "../../utils";
import { API_ROUTES } from "../../../../../../../system/constants/apiRoutes";

const GeoDetails = ({ currentCountry, reqFilters }) => {
  const [getDetails, { data, isLoading }] = useGetGeoDetails();
  const [getFile] = useGetReportFile(
    API_ROUTES.reports.geo_details.path,
    `${currentCountry}_${API_ROUTES.reports.geo_details.filename}`,
    {
      ...reqFilters,
      country: currentCountry,
    }
  );
  const clickHandler = () => {
    getFile();
  };

  useEffect(() => {
    getDetails({
      ...reqFilters,
      country: currentCountry,
    });
  }, [currentCountry]);

  const tableData: any = data && Object.values(data?.models);
  const subTotals = useSubtotals(
    tableData,
    config.subtotals,
    createColumns(config.columns)
  );

  const translatedColumns = createColumns(config.columns).map((c) => {
    return {
      ...c,
      title: _t(c.title),
    };
  });
  return (
    <div className="geo-detail-modal">
      <div>
        <ExcelButton clickHandler={clickHandler} isLoading={false} />
      </div>
      <Spin spinning={isLoading} />

      {!isLoading && data && (
        <Table
          columns={translatedColumns}
          dataSource={tableData.map((item) => ({
            ...item,
            date: reqFilters.date.from,
          }))}
          rowKey={(item) => item?.partner_id}
          pagination={false}
          summary={() => {
            return (
              <>
                {isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin spinning={isLoading} />
                  </div>
                ) : (
                  <>
                    {data && (
                      <RenderSubtotals
                        subTotals={subTotals}
                        translatedColumns={createColumns(config.columns)}
                        date={reqFilters.date.from}
                      />
                    )}
                  </>
                )}
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default GeoDetails;
