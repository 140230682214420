import React from "react";
import { Card } from "antd";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useAccrualsList } from "../apiHooks";
import { CurrencyLabel } from "../../Common/components";
import { _t } from "../../Common/components/InjectIntlContext";
import { NoData } from "../../Common/components/NoData";
import { getCurrencySymbol } from "../../../common/helpers";

const columns = (_t): ColumnsType<any> => [
  {
    title: _t("accounting_period"),
    dataIndex: "period",
  },
  {
    title: _t("amount"),
    dataIndex: "partner_income",
    render: (val, record) => (
      <CurrencyLabel value={val} symbol={getCurrencySymbol(record.period)} />
    ),
  },
  {
    title: _t("first_depositors"),
    dataIndex: "first_deposits",
  },
];

function AccrualsTable({ data, loading }: { data: any[]; loading: boolean }) {
  const translatedColumns = columns(_t);
  return (
    <Table
      loading={loading}
      columns={translatedColumns}
      dataSource={data}
      pagination={false}
      locale={{ emptyText: <NoData /> }}
    />
  );
}

export const AccrualsWidget = () => {
  const { data, isLoading } = useAccrualsList();

  return (
    <Card title={_t("Accruals")} style={{ height: "100%" }}>
      <AccrualsTable loading={isLoading} data={data} />
    </Card>
  );
};
