import React from "react";
import { Form, Select } from "antd";
import { useGetStyles } from "./hooks";
import { createOptions } from "../../../../../../common/helpers";
import { NotificationStyleSelectType } from "./types";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";

const NotificationStyleSelect: React.FC<NotificationStyleSelectType> = ({
  initialValue,
}) => {
  const { data, isLoading } = useGetStyles();
  const getOptions = () => {
    if (!isLoading) {
      return createOptions(data?.styles, "name").map((item) => {
        return { ...item, value: `${+item.value + 1}` };
      });
    }
  };
  return (
    <Form.Item
      name="global_notification_style_id"
      initialValue={initialValue ? initialValue + "" : "1"}
      label={_t("notification_card_style_select")}
    >
      <Select options={getOptions()} loading={isLoading} />
    </Form.Item>
  );
};

export default NotificationStyleSelect;
