import React from "react";
import { Card, Row, Col, Spin, List, Typography } from "antd";
import { lineOptions, isCurrencyField } from "../../constants";
import { useFilters } from "../../hooks";
import { CurrencyLabel } from "../../../Common/components";
import { _t } from "../../../Common/components/InjectIntlContext";
import { CellPartnerIncome } from "./components";
import { getCurrencySymbol } from "../../../../common/helpers";

const CardItem = ({ label, children }) => (
  <List.Item style={{ background: "white", height: "100%" }}>
    <List.Item.Meta
      style={{ textAlign: "center" }}
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {children}
        </Typography.Title>
      }
      description={label}
    />
  </List.Item>
);

export function TableTotals({ isLoading, totals }) {
  const [{ totals: totalsColumns, date }] = useFilters();
  const lineOptionsTranslated = lineOptions(_t);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[10, 10]}>
        <Col xs={5}>
          <Card className="dashboard-partner-income">
            <CellPartnerIncome
              {...totals}
              symbol={getCurrencySymbol(date.from)}
            />
          </Card>
        </Col>
        <Col xs={19}>
          <Card className="dashboard-totals">
            <Row gutter={[1, 1]}>
              {totals ? (
                lineOptionsTranslated
                  .filter((option) => totalsColumns.includes(option.value))
                  .map((column) => {
                    const value = totals?.[column.value] || 0;
                    return (
                      <Col xs={12} md={6} xl={4} key={column.value}>
                        <CardItem label={column.label}>
                          {isCurrencyField(column.value) ? (
                            <CurrencyLabel
                              value={value}
                              symbol={getCurrencySymbol(date.from)}
                            />
                          ) : (
                            value
                          )}
                        </CardItem>
                      </Col>
                    );
                  })
              ) : (
                <div style={{ minHeight: 158 }} />
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
}

export function GamersTableTotals({
  isLoading,
  totals,
  totalsColumns,
  date,
}: {
  isLoading: boolean;
  totals: any;
  totalsColumns: string[];
  date: any;
}) {
  return (
    <Row gutter={[10, 10]}>
      <Col xs={5}>
        <Card className="dashboard-partner-income">
          <CellPartnerIncome
            {...totals}
            symbol={getCurrencySymbol(date.from)}
          />
        </Card>
      </Col>
      <Col xs={19}>
        <Card className="dashboard-totals dashboard-totals--gamers">
          <Spin spinning={isLoading}>
            <Row gutter={[1, 1]}>
              {totals ? (
                totalsColumns.map((column) => {
                  const value = totals?.[column] || 0;
                  return (
                    <Col xs={12} md={6} xl={4} key={column}>
                      <CardItem label={_t(column)}>
                        {isCurrencyField(column) ? (
                          <CurrencyLabel
                            value={value}
                            symbol={getCurrencySymbol(date.from)}
                          />
                        ) : (
                          value
                        )}
                      </CardItem>
                    </Col>
                  );
                })
              ) : (
                <div style={{ minHeight: 158 }} />
              )}
            </Row>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}
