import React, { useMemo, useState } from "react";
import { Card } from "antd";
import { SettingsForm } from "./SettingsForm";
import { ReportTable, reportColumns } from "./ReportTable";
import { columnOptions, visibleColumnsKey } from "../../constants";
import { useFilters } from "../../hooks";
import { ColumnType } from "antd/lib/table";
import { _t } from "../../../Common/components/InjectIntlContext";
import {
  getItem,
  setItem,
} from "../../../../system/helpers/localstorageHelper";
import { useStatsUpdateTime } from "../../apiHooks";
import { store } from "../../../../system/store";
import filters from "../../reducer";

const selectableColumns = reportColumns;

function useSettings<Record>({
  allColumns,
}: {
  allColumns: ColumnType<Record>[];
}) {
  const [filters, updateFilters] = useFilters();
  const allColumnValues = selectableColumns.map(
    (column: any) => column.dataIndex
  );

  const getColumns = (columns) => {
    const columnsSet = new Set(columns);
    const columnsToOmitSet = new Set(
      allColumnValues.filter((name) => !columnsSet.has(name))
    );
    return allColumns.filter(
      (column) => !columnsToOmitSet.has(column.dataIndex)
    );
  };

  const storageVisibleColumns = getItem(visibleColumnsKey);
  const defaultColumns = columnOptions
    .filter((field) => !field.hidden)
    .map((field) => field.value);
  const visibleColumnsArray = storageVisibleColumns
    ? JSON.parse(storageVisibleColumns)
    : defaultColumns;
  const [visibleColumns, setVisibleColumns] = useState(
    getColumns(visibleColumnsArray)
  );

  const settings = useMemo(
    () => ({
      columns: visibleColumnsArray,
      page_size: filters.page_size,
      groupBy: filters.groupBy,
    }),
    [filters.page_size, filters.groupBy]
  );

  const onChange = ({
    columns,
    page_size,
    groupBy,
  }: {
    columns: string[];
    page_size: string;
    groupBy;
  }) => {
    setItem(visibleColumnsKey, JSON.stringify(columns));
    setVisibleColumns(getColumns(columns));
    updateFilters({ page_size, groupBy });
  };

  return {
    onChange,
    visibleColumns,
    settings,
  };
}
type ReportTableWidgetType = {
  data?: any;
  isLoading?: boolean;
  allColumns?: any;
  showGroupBy?: boolean;
  showSettings?: boolean;
  date?: any;
};
export const ReportTableWidget: React.FC<ReportTableWidgetType> = ({
  data,
  isLoading,
  allColumns,
  showGroupBy = false,
  showSettings = true,
  date,
}) => {
  let { onChange, visibleColumns, settings } = useSettings({
    allColumns,
  });

  const {
    isSuccess: statsUpdateTimeSuccess,
    data: updateTime,
  } = useStatsUpdateTime();

  const time = statsUpdateTimeSuccess ? updateTime.data : "--:--";
  if (
    showGroupBy &&
    settings.groupBy === "total" &&
    !isLoading &&
    data?.models?.data
  ) {
    const item = data?.models?.data[0];
    if (item) {
      visibleColumns = visibleColumns.filter((column) => {
        return Object.prototype.hasOwnProperty.call(
          item,
          column.dataIndex as string
        );
      });
    }
  }

  const columnOptionsTranslated = columnOptions.map((item) => {
    return {
      value: item.value,
      label: _t(item.label),
    };
  });
  return (
    <Card>
      {showSettings && (
        <SettingsForm
          onSubmit={onChange}
          initialValues={settings}
          columnsOptions={columnOptionsTranslated}
          showGroupBy={showGroupBy}
          updateTime={time}
        />
      )}
      <div className="system-table">
        <ReportTable
          data={data?.models?.data}
          total={data?.models?.total}
          isLoading={isLoading}
          columns={visibleColumns}
          pageSize={settings.page_size}
          date={date}
          groupBy={settings.groupBy === "total"}
        />
      </div>
    </Card>
  );
};
