import React, { useState } from "react";
import { Card } from "antd";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TranslationsListResponse } from "../constants";
import { _t } from "../../Common/components/InjectIntlContext";
import Btn from "../../Common/components/Button";
import { Link } from "react-router-dom";
import { NoData } from "../../Common/components/NoData";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("default_message"),
    dataIndex: "default_message",
    render: (v) => {
      return <span>{v}</span>;
    },
  },
  {
    title: _t("translation"),
    dataIndex: "translation",
    render: (v) => {
      return <span>{v && v.length > 50 ? v.substring(0, 50) + "..." : v}</span>;
    },
  },
  {
    title: _t("edit"),
    dataIndex: "key_id",
    render: (v) => {
      return (
        <Link to={`/translations/admin/show/${v}`}>
          <Btn type="primary" title={_t("edit")}></Btn>
        </Link>
      );
    },
  },
];

type TranslationsListWidgetWidgetProps = {
  data: TranslationsListResponse;
  isLoading: boolean;
  page: any;
  setPage: any;
  pageSize: any;
};
type TranslationsListTableProps = {
  data: any;
  loading: boolean;
  page: any;
  setPage: any;
  pageSize: any;
};

function TranslationsListTable({
  data,
  loading,
  page,
  setPage,
  pageSize,
}: TranslationsListTableProps) {
  const s = columns(_t);
  return (
    <Table
      loading={loading}
      columns={s}
      dataSource={data?.data}
      rowKey={(r) => r.key_id}
      pagination={{
        total: data?.total,
        hideOnSinglePage: true,
        onChange: setPage,
        current: page,
        pageSize: pageSize,
        showSizeChanger: false,
      }}
      locale={{ emptyText: <NoData /> }}
    />
  );
}

export const TranslationsListWidget = ({
  data,
  isLoading,
  page,
  setPage,
  pageSize,
}: TranslationsListWidgetWidgetProps) => {
  return (
    <Card title={_t("keys")}>
      <TranslationsListTable
        loading={isLoading}
        data={data?.translations}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
      />
    </Card>
  );
};
