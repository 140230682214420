import * as actionTypes from './constants';
import React from "react";

const AUTH_URL = '/api/login';
const LOGOUT_URL = '/api/logout';
const REFRESH_TOKEN_URL = '/api/refresh';
const STATS_URL = '/api/stats/chart/data';

export const signinAction = (data) => (
  {
    types: 'AUTH',
    request:{
      url: AUTH_URL,
      data: data,
      method: 'POST',
    },
  }
);

export const logoutAction = () => dispatch => (
  dispatch({
    types: [actionTypes.LOGOUT_START, actionTypes.LOGOUT_SUCCESS, actionTypes.LOGOUT_FAIL],
    request:{
      url: LOGOUT_URL,
      method: 'POST',
    },
  })
);

export const statAction = data => dispatch => (
  dispatch({
    types: [actionTypes.STATS_START, actionTypes.STATS_SUCCESS, actionTypes.STATS_FAIL],
    request:{
      url: STATS_URL,
      method: 'POST',
      data: data,
    },
  })
);

export const refreshTokenAction = () => dispatch => (
  dispatch(
    {
      types: 'REFRESH_TOKEN',
      request:{
        url: REFRESH_TOKEN_URL,
        method: 'GET',
      },
    })
);

