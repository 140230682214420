import React, { useState } from "react";
import { DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { Moment } from "moment";

type RangeValue = [Moment | null, Moment | null] | null;

const DateRangePicker = ({
  onChange,
  value,
  clearable = false,
  disabledDate,
  allowEmpty = [false, false],
}: {
  value?;
  onChange?: (value) => any;
  clearable?: boolean;
  disabledDate?: any;
  allowEmpty?: [boolean, boolean];
}) => {
  const [dateRangeValue, setDateRangeValue] = useState<RangeValue>([
    value?.from,
    value?.to,
  ]);
  const [hackValue, setHackValue] = useState<RangeValue>(null);

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDateRangeValue([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (
    <DatePicker.RangePicker
      value={dateRangeValue || hackValue}
      onChange={(dates) => {
        setDateRangeValue([dates[0], dates[1]]);
        onChange(dates);
      }}
      onCalendarChange={(val) => setDateRangeValue(val)}
      onOpenChange={onOpenChange}
      allowClear={clearable}
      separator={<CalendarOutlined />}
      suffixIcon={null}
      disabledDate={(current) => {
        return disabledDate && disabledDate(current, dateRangeValue);
      }}
      className="date-range-picker"
      allowEmpty={allowEmpty}
    />
  );
};

export default DateRangePicker;
