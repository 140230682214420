import React from "react";
import { useSelector } from "react-redux";

import { Spin, Table } from "antd";

import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { useGetAffiliateGeoDetail } from "./api";
import { RenderSubtotals } from "../../components";
import { createColumns } from "../../utils";
import { config } from "./config";
import { useSubtotals } from "../../hooks/table";
import { ExcelButton } from "../../../../../modules/Dashboard/components/Filters/ExcelButton";
import { useGetReportFile } from "../../hooks/api";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { getItem } from "../../../../../system/helpers/localstorageHelper";
import { config as reportConfig } from "../AffiliatesGeoReport/config";
import moment from "moment";

const AffiliatesGeoReportDetail = ({ partnerId }) => {
  const { date, country } = useSelector((state) => state.reportGeoAffiliates);
  const params = {
    partner_id: partnerId,
    country,
    from: moment(date.from).format("YYYY-MM-DD"),
    to: moment(date.to).format("YYYY-MM-DD"),
  };
  const { data, isLoading } = useGetAffiliateGeoDetail(params);

  const subTotals = useSubtotals(
    data?.models?.data,
    config.subtotals,
    createColumns(config.columns)
  );

  const translatedColumns = createColumns(config.columns).map((c) => {
    return {
      ...c,
      title: _t(c.title),
    };
  });
  const [getFile] = useGetReportFile(
    API_ROUTES.reports.affiliates_geo_details.path,
    API_ROUTES.reports.affiliates_geo_details.filename,
    {
      ...params,
    }
  );
  const clickHandler = () => {
    getFile();
  };
  return (
    <div className="affiliate-geo-detail-modal">
      <div style={{ marginBottom: "15px" }}>
        <ExcelButton clickHandler={clickHandler} isLoading={false} />
      </div>
      {!isLoading && data ? (
        <Table
          columns={translatedColumns}
          dataSource={data?.models.data.map((item) => ({
            ...item,
            date: date.from,
          }))}
          rowKey={(item) => item?.country}
          pagination={false}
          summary={() => {
            return (
              <>
                {isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin spinning={isLoading} />
                  </div>
                ) : (
                  <>
                    {data && (
                      <RenderSubtotals
                        subTotals={subTotals}
                        translatedColumns={createColumns(config.columns)}
                        date={date.from}
                      />
                    )}
                  </>
                )}
              </>
            );
          }}
        />
      ) : (
        <Spin spinning />
      )}
    </div>
  );
};

export default AffiliatesGeoReportDetail;
