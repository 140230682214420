import {
  getPromoPrograms,
  getPromoTypes,
  getPromoProjects,
  getPromoMaterials,
  sendOrder,
  getPromoProjectTypes,
  getRawCode,
  getPromoTrackers,
  updatePromoMaterial,
  getPromoSourceOptions,
  getPromoTemplates,
  getPromoLandings,
  createPromoMaterial,
  getPromoArchive,
} from "./api";
import { PromoTemplatesRequestData } from "./constants";
import { useQuery, useMutation, queryCache } from "react-query";
import { notify } from "../../common/helpers";
import { history } from "../../system/store";

const PromoProgramsList = "promo-programs";
const PromoTypesList = "promo-types";
const PromoProjectsList = "promo-projects";
const PromoMaterialsList = "promo-materials";
const PromoTrackersList = "promo-trackers";
const PromoSourceOptions = "promo-source-options";
const PromoTemplates = "promo-templates";
const PromoArchiveList = "promo-archive";

export const usePromoPrograms = () => {
  return useQuery([PromoProgramsList], getPromoPrograms);
};

export const usePromoTypes = () => {
  return useQuery([PromoTypesList], getPromoTypes);
};

export const usePromoProjectTypes = (projectId: string) => {
  return useQuery([PromoTypesList], () => getPromoProjectTypes(projectId));
};

export const usePromoProjects = () => {
  return useQuery([PromoProjectsList], getPromoProjects);
};

export const usePromoArchive = () => {
  return useQuery([PromoArchiveList], getPromoArchive);
};

export const usePromoMaterials = (params) => {
  return useQuery([PromoMaterialsList, params], (k, params) =>
    getPromoMaterials(params)
  );
};

export const useGetPromoTrackers = () => {
  return useQuery([PromoTrackersList], getPromoTrackers);
};

export const useGetPromoSourceOptions = (params) => {
  return useQuery([PromoSourceOptions, params], (k, params) =>
    getPromoSourceOptions(params)
  );
};

export const useGetPromoTemplates = (params) => {
  return useQuery([PromoTemplates, params], (k, params) =>
    getPromoTemplates(params)
  );
};

export const useGetPromoLandings = (params) => {
  return useQuery([PromoTemplates, params], (k, params) =>
    getPromoLandings(params)
  );
};

export const useUpdatePromoTemplates = () => {
  return useMutation(
    (params: PromoTemplatesRequestData) => getPromoTemplates(params),
    {
      onSuccess: (data, variables) => {
        const req = { project_id: variables.project_id, type: variables.type };
        queryCache.setQueryData([PromoTemplates, req], data);
      },
      onError: () => {
        notify("error", "error");
      },
    }
  );
};

export const useSendOrder = () => {
  return useMutation(sendOrder, {
    onSuccess: (data, variables) => {
      notify("success", data.message);
    },
    onError: () => {
      notify("error", "error");
    },
  });
};

export const useCreatePromoMaterial = () => {
  return useMutation(createPromoMaterial, {
    onSuccess: (data, variables) => {
      notify("success", data.status);
      history.push("/promo/archive");
    },
    onError: () => {
      notify("error", "error");
    },
  });
};

export const useUpdatePromoMaterial = () => {
  return useMutation((data) => updatePromoMaterial(data), {
    onSuccess: (data, variables) => {
      notify("success", data.status);
      queryCache.invalidateQueries(PromoMaterialsList);
    },
    onError: () => {
      notify("error", "error");
    },
  });
};

export const useGetRawCode = () => {
  return useMutation(getRawCode);
};
