import React from "react";

export const SUCCESS_STATUS_MESSAGE = "success";
export const OK_STATUS_MESSAGE = "ok";

export const AdminCreateUserProjectsQuery = "admin-create-user-projects";

export const YES_NO_RADIO = (_t: any) => ({
  0: _t("No"),
  1: _t("yes"),
});

export const DISABLED_ENABLED_RADIO = (_t: any) => ({
  0: _t("disabled"),
  1: _t("enabled"),
});

export const STATUSES_RADIO = (_t: any) => ({
  1: _t("active"),
  2: _t("banned"),
  3: _t("Suspend"),
  4: _t("hold"),
});

export const LANGUAGES_RADIO = (_t: any) => ({
  ru: "Русский",
  en: "English",
  tr: "Turkish",
  ja: "Japanese",
  pr: "Portuguese",
});

export interface User {
  id: number;
  login: string;
  email: string;
  activated_at: string;
}

export interface UsersListResponse {
  current_page: number;
  data: User[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}

export interface UsersListRequest {
  search?: string;
  page?: number;
  page_size?: number;
}

export interface UsersProfileRequest {
  user_id: number;
}

export interface UpdateProfileRequest {
  partner_id: number;
  field_name: string;
  value: string;
  messages: any;
}

export interface UpdateProfileTRequest {
  data: UpdateProfileRequest;
  messages: any;
}

export type UserProps = {
  activated: number;
  activated_at: null | string;
  balance: number;
  balance_current_period: number;
  cash_type: null | number;
  created_at: string;
  csv_allowed_ips: null | string;
  csv_key: string;
  csv_settings: number;
  email: string;
  first_deps: number;
  first_name: string;
  hash: any;
  hash_time: any;
  http_referer: any;
  id: number;
  invited_with: string;
  invited_with_promo: any;
  is_subscribed: number;
  language: string;
  last_login: string;
  last_name: string;
  login: string;
  notes: string;
  payouts: number;
  phone: string;
  profit: string;
  remember_token: string;
  resources: string;
  role: number;
  show_in_tops: number;
  skype: string;
  status: number;
  subscription_language: string;
  updated_at: string;
  use_redirector: number;
  vip_status: number;
  wallet: string;
  manager_id: number;
  manager: string;
  email_verified: string;
};

export type UserSettingsProps = {
  allow_postbacks: number;
  contract: any;
  custom_nick: string;
  ex_ussr: number;
  hide_finance: number;
  id: number;
  ignore_pokerdom_slots: number;
  manager_id: number;
  negative_carryover: number;
  postback_general_deposit_url: string;
  postback_general_first_deposit_url: string;
  postback_general_install_url: string;
  postback_general_qualification_url: string;
  postback_general_register_url: string;
  referrer: string;
  referrer_host: string;
  show_gamers_login: number;
  traffic_type: number;
  unhold_notes: string;
  user_id: number;
};

export type ManagerProps = {
  id: number;
  string: string;
};

export type ProfileBasicInformation = {
  user: UserProps;
  user_settings: UserSettingsProps;
  managers: ManagerProps[];
  cash_types: any;
  traffic_types: any;
  projects: any;
  last_stats_view?: any;
  ex_ussr: any;
  reset_link: string;
  remain_password_key: string;
};

export interface AccrualsRequest {
  user_id: string;
}

export interface AccrualInterface {
  amount: number;
  start_date: string;
  end_date: string;
}

export interface AccrualsResponse {
  data: AccrualInterface[];
}

export interface PayoutsRequest {
  user_id: string;
}

export interface PayoutsInterface {
  amount: number;
  created_at: string;
  id: string;
  wallet: string;
  comment?: string;
}

export interface PayoutsResponse {
  data: PayoutsInterface[];
}

export interface AuthLogRequest {
  user_id: string;
  limit?: number;
}

export interface AuthLogInterface {
  ip: string;
  is_ggs_auth: string;
  is_old_db_auth: string;
  success: number;
  timestamp: string;
  user_id: number;
}

export interface AuthLogResponse {
  data: AuthLogInterface[];
}

export interface MultiplesRequest {
  user_id: string;
}

export interface MultiplesInterface {
  ip: string;
  is_old_db_auth: string;
  login: string;
  user_id: number;
}

export interface MultiplesResponse {
  data: MultiplesInterface[];
}

export interface GamerMultiplesRequest {
  user_id: string;
}

export interface GamerMultiplesInterface {
  id: number;
  partner_id: number;
  ip: string;
  gamer_id: string;
  comment: string;
}

export interface GamerMultiplesResponse {
  data: GamerMultiplesInterface[];
}

export interface BonusesRequest {
  user_id: string;
}

export interface BonusesInterface {
  id: number;
  created_at: string;
  type: string;
  amount: number;
  comment: string;
  visible: number;
}

export interface BonusesResponse {
  data: BonusesInterface[];
}

export interface WalletLogsRequest {
  user_id: string;
}

export interface WalletLogInterface {
  key: number;
  editor_id: number;
  editor_login: string;
  editor_ip: string;
  date: string;
  data_json: any;
  data_json_parsed: any;
}

export interface WalletLogsResponse {
  data: WalletLogInterface[];
}

export interface StatusLogsRequest {
  user_id: string;
}

export interface StatusLogInterface {
  key: number;
  editor_id: number;
  editor_login: string;
  editor_ip: string;
  date: string;
  data_json: any;
  data_json_parsed: any;
}

export interface StatusLogsResponse {
  data: StatusLogInterface[];
}

export interface RoleLogsRequest {
  user_id: string;
}

export interface RoleLogInterface {
  key: number;
  editor_id: number;
  editor_login: string;
  editor_ip: string;
  date: string;
  data_json: any;
  data_json_parsed: any;
}

export interface RoleLogsResponse {
  data: RoleLogInterface[];
}

export interface NullingLogsRequest {
  user_id: string;
}

export interface NullingLogInterface {
  key: number;
  editor_id: number;
  editor_login: string;
  editor_ip: string;
  date: string;
  data_json: any;
  data_json_parsed: any;
}

export interface NullingLogsResponse {
  data: NullingLogInterface[];
}

export interface ProjectsResponse {
  data: any;
}

export interface AddOperationRequest {
  partner_id: string;
  amount: string;
  operation: string;
  project_id?: string;
  comment: string;
  visible?: string;
}

export interface SpecialProjectsRequest {
  user_id: string;
}

export interface SpecialProjectsResponse {
  projects: any;
  projects_available: any;
}

export interface AllowSpecialProjectRequest {
  user_id: string;
  project_id: string;
}

export interface DenySpecialProjectRequest {
  user_id: string;
  project_id: string;
}

export interface SpecialProgramsRequest {
  user_id: string;
}

export interface SpecialProgramsResponse {
  programs: any;
  programs_available: any;
}

export interface AllowSpecialProgramRequest {
  user_id: string;
  program_id: string;
}

export interface DenySpecialProgramRequest {
  user_id: string;
  program_id: string;
}

export interface SpecialLandingPagesRequest {
  user_id: string;
}

export interface SpecialLandingPagesResponse {
  landings: any;
  landings_available: any;
}

export interface AllowSpecialLandingPageRequest {
  user_id: string;
  landing_page_id: string;
}

export interface DenySpecialLandingPageRequest {
  user_id: string;
  landing_page_id: string;
}

export interface AdminProjectsResponse {
  data: {
    projects: AdminProjects[];
  };
}

export interface AdminProjects {
  id: string;
  name: string;
}

export interface CreateUser {
  login: string;
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  email: string;
  projects: any;
}

export const layoutUserCreateForm = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
  },
};
export const tailLayoutUserCreateForm = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12, offset: 8 },
    lg: { span: 8, offset: 8 },
  },
};

export interface SpecialProgramOptionsRequest {
  user_id: string;
}

export interface SpecialProgramOptionsResponse {
  options: SpecialProgramOption[];
}

export interface SpecialProgramOption {
  id: number;
  full_name: string;
  amount: number;
  dates_status: string;
  start_date: string;
  stop_date: string;
  status: string;
  terminable: boolean;
}

export interface SpecialProgramOptionsListRequest {
  user_id: string;
}

export interface SpecialProgramOptionsListResponse {
  programs: any;
}

export interface DenySpecialProgramOptionRequest {
  user_id: number;
  id: number;
}

export interface AllowSpecialProgramOptionsRequest {
  user_id: string;
  program_id: string;
  amount: number;
  start_date: string;
  stop_date?: string;
}
