import moment from "moment";
import {
  ADDITIONAL_CURRENCY_SYMBOL,
  ADDITIONAL_CURRENCY_SYMBOL_DATE,
} from "../../system/config";
import config from "../../system/config";

export const getCurrencySymbol = (date, status = "default") => {
  const isAdditionalSymbol = moment(date).isSameOrAfter(
    ADDITIONAL_CURRENCY_SYMBOL_DATE
  );
  return isAdditionalSymbol
    ? ADDITIONAL_CURRENCY_SYMBOL
    : config.projectCurrency;
};
