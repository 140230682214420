import React from "react";
import { Card } from "antd";
import { Tabs } from "antd";
import { ByDaysWidget } from "./ByDaysWidget";
import { ByInvitesWidget } from "./ByInvitesWidget";
import { BySubaffiliatesWidget } from "./BySubaffiliatesWidget";
import { _t } from "../../Common/components/InjectIntlContext";

const { TabPane } = Tabs;

export const DetailsWidget = () => {
  return (
    <Card bodyStyle={{ padding: "0 24px" }}>
      <Tabs defaultActiveKey="1" tabBarStyle={{ marginBottom: 0 }}>
        <TabPane tab={_t("by_days")} key="1">
          <ByDaysWidget />
        </TabPane>
        <TabPane tab={_t("by_invites")} key="2">
          <ByInvitesWidget />
        </TabPane>
        <TabPane tab={_t("by_sub_affiliates")} key="3">
          <BySubaffiliatesWidget />
        </TabPane>
      </Tabs>
    </Card>
  );
};
