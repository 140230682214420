import { Moment } from "moment";

type RangeValue = [Moment | null, Moment | null] | null;
export const datePickerDividerValidation = (
  current: Moment,
  dates: RangeValue,
  dateDivider: string
) => {
  if (dates[0]) {
    const validationNotRequired =
      dates[0].isSame(dateDivider) || dates[0].isAfter(dateDivider);
    if (validationNotRequired) return false;
    return !current.isBetween(current.format("YYYY-MM-DD"), dateDivider);
  }
  if (dates[1]) {
    const validationNotRequired = dates[1].isBefore(dateDivider);
    if (validationNotRequired) return false;
    if (dates[1].isSame(dateDivider)) return !dates[1].isSame(dateDivider);
    return !current.isAfter(dateDivider);
  }
};
