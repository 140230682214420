import React from "react";
import { useParams } from "react-router-dom";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useNewsContent } from "../apiHooks";
import LandingTemplate from "../../Layouts/LandingTemplate";
import {Card, Spin} from "antd";
import { PostView } from "../components/PostView";

export function NewsContent() {
  const { slug } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useNewsContent({
    language: locale,
    slug: slug,
  });

  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <section className="news-list">
        <Spin spinning={isLoading}>
          <h2>{data?.post.html_title}</h2>
          <br />
          {data && (
            <div key={data.post.id}>
              <PostView post={data.post} />
            </div>
          )}
        </Spin>
      </section>
    </LandingTemplate>
  );
}
