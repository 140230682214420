import React from "react";
import icon from "../../../../assets/images/outer/commission_2.svg";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";

const Cpa = () => {
  const points = (_t: any) => [
    _t("cpa_1"),
    _t("cpa_2"),
    _t("cpa_3"),
    // _t("cpa_4"),
    _t("cpa_5"),
    _t("cpa_6"),
    _t("cpa_7"),
  ];
  const title = _t("cpa_title");
  return <CommissionPage title={title} icon={icon} items={points} />;
};

export default Cpa;
