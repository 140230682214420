import React, { useMemo } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { PageTemplate } from "../../Layouts/PageTemplate";
import ArchiveFilters from "../components/archive/Filter";
import { usePromoTypes, usePromoProjects, usePromoArchive } from "../apiHooks";
import PromoData from "../components/archive/PromoData";
import { createPromoOptions } from "../../Dashboard/utils";

const Archive = () => {
  const { data: typesData, isLoading: loadingTypes } = usePromoTypes();
  const { data: projectsData, isLoading: loadingProjects } = usePromoProjects();
  const { data: promosData, isLoading: loadingPromos } = usePromoArchive();
  const promoOptions = useMemo(() => {
    return createPromoOptions(promosData);
  }, [promosData?.promos]);
  return (
    <PageTemplate
      title={_t("Archive of promo’s")}
      additionalClass="promo-archive"
    >
      <ArchiveFilters
        typesData={typesData?.data}
        projectsData={projectsData?.data}
        promoOptions={promoOptions}
        loadingTypes={loadingTypes}
        loadingProjects={loadingProjects}
        loadingPromos={loadingPromos}
      />
      <PromoData />
    </PageTemplate>
  );
};

export default Archive;
